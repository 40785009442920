import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';

const PoliciesDataProtectionRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='PoliciesDataProtectionRoute'>

      <SiteHelmet title='Data Protection Policy - AfterCrafts' />

      <PageSection width='narrow'>
        <h1>Data Protection Policy</h1>
        <p>At AfterCrafts we are committed to protecting the privacy and personal information of our participants and their parents or guardians. This Data Protection Policy outlines how we collect, use, disclose, and store personal data in compliance with relevant data protection laws and regulations. We strive to ensure that all personal information is handled responsibly and securely.</p>
        <h3>Collection and use of personal data</h3>
        <p>We will only collect personal data that is necessary for the registration and participation of children in our craft classes. This may include the child's name, age, contact information (such as parent/guardian's name, address, email, and phone number), and any relevant medical information.</p>
        <p>The personal data collected will be used solely for the purpose of administering the craft classes, communicating with parents/guardians, ensuring the safety and well-being of the children, and providing an enjoyable experience.</p>
        <p>We will not use children's personal data for any marketing or promotional purposes without first obtaining consent from their parents or guardians. This consent can be withdrawn at any point.</p>
        <p>When classes are held at a school that the child attends we provide them with a list of who is attending the class (name and class only). If your child has an allergy, medical condition or special need we may discuss with the school where any medication is kept or any special facilities needed to ensure for your child can attend safely. We will ask your consent if this is necessary.</p>
        <h3>Disclosure of personal data</h3>
        <p>We will only disclose children's personal data to authorised individuals involved in the administration of the craft classes, such as instructors and staff members, on a need-to-know basis.</p>
        <p>We will not share children's personal data with any third parties without prior consent from their parents/guardians, unless required by law or as necessary to protect the safety and well-being of the children.</p>
        <h3>Data retention and security</h3>
        <p>We will retain children's personal data for as long as necessary to fulfill the purposes outlined in this policy, or as required by applicable laws and regulations.</p>
        <p>We will implement appropriate technical and organisational measures to protect personal data against unauthorized access, loss, misuse, or alteration.</p>
        <p>We will regularly review and update our security measures to ensure ongoing data protection.</p>
        <h3>Parent/guardian rights</h3>
        <p>Parents/guardians have the right to access, correct, or delete their child's personal data held by us, upon request.</p>
        <h3>Data breach notification</h3>
        <p>In the event of a data breach that poses a risk to children's personal data, we will promptly notify affected parents/guardians and take appropriate steps to mitigate the breach.</p>
        <p>This Data Protection Policy will be made available to parents/guardians and posted on our website. By enrolling their children in our craft classes, parents/guardians acknowledge and consent to the collection, use, and storage of their child's personal data as described in this policy.</p>
        <p>If you have any questions or concerns regarding our data protection practices, please contact us using the information provided.</p>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { PoliciesDataProtectionRoute };
export default PoliciesDataProtectionRoute;