import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Blurhash } from 'react-blurhash';
import { useInView } from 'react-intersection-observer';
import './LazyImage.scss';

type LazyImageProps = {
  src: string;
  blurhash?: string;
  backgroundColor?: string;
  aspectRatio?: number;
  height?: string;
  className?: string;
  alt?: string;
}

const LazyImage = (props: LazyImageProps) => {

  const { ref, inView } = useInView({ threshold: 0, triggerOnce: true });

  const lazyImageStyles: React.CSSProperties = {};
  if (props.backgroundColor) lazyImageStyles.backgroundColor = props.backgroundColor;

  const imageStyles: React.CSSProperties = {};
  if (props.aspectRatio) imageStyles.aspectRatio = props.aspectRatio;
  if (props.height) imageStyles.height = props.height;

  const [imageSrc, setImageSrc] = useState<string>('');
  useEffect(() => {
    if (!inView) return;
    setImageSrc(props.src);
  }, [inView, props.src]);

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const handleImageLoad = (evt: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setImageLoaded(true);
  };

  const blurhashClasses = clsx('LazyImage__blurhash', {
    'LazyImage__blurhash--hide': imageLoaded,
  });

  const lazyImageClasses = clsx('LazyImage', props.className);

  return (
    <div className={lazyImageClasses} style={lazyImageStyles}>
      {props.blurhash &&
        <Blurhash className={blurhashClasses} hash={props.blurhash} width='100%' height='100%' />
      }
      <img
        ref={ref}
        className='LazyImage__image'
        style={imageStyles}
        src={imageSrc}
        onLoad={handleImageLoad}
        alt={props.alt} />
    </div>
  );
}

export { LazyImage };
