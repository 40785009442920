import { useState } from 'react';
import './HeroVideoBanner.scss';

type HeroVideoBannerProps = {
  title: string;
  image: string;
};

const HeroVideoBanner = (props: HeroVideoBannerProps) => {

  const [email, setEmail] = useState('');
  const handleInputEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const encode = (data: any) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  const [signupSuccess, setSignupSuccess] = useState(false);
  const handleFormSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'launchSignup', email }),
    })
      .then(() => setSignupSuccess(true));
    // .catch(error => alert(error));
    // This is required to prevent redirect to form submission page
    evt.preventDefault();
  };

  return (
    <div className='HeroVideoBanner'>
      <div className='HeroVideoBanner__image' style={{ backgroundImage: `url(${props.image})` }}>
        <div className='HeroVideoBanner__imageOverlay'>
          <div className='HeroVideoBanner__text'>
            <div className='HeroVideoBanner__pill'>
              <div className='HeroVideoBanner__pillText'>Now booking for Autumn Term 2024!</div>
            </div>
            <div className='HeroVideoBanner__tagLine u-font-brand'>{props.title}</div>
            <div className='HeroVideoBanner__signupText u-font-brand'>Sign up to be notified when more classes are listed.</div>
            <div className='HeroVideoBanner__form'>
              {!signupSuccess &&
                <form name='launchSignup' method='post' onSubmit={handleFormSubmit}>
                  <input type='hidden' name='form-name' value='launchSignup' />
                  <input className='HeroVideoBanner__formEmail' type='email' name='email' placeholder='Email address' onChange={handleInputEmailChange} />
                  <button className='HeroVideoBanner__formButton button' type='submit'>Send</button>
                </form>
              }
              {signupSuccess &&
                <div className='HeroVideoBanner__signupSuccess'>
                  Thank you for subscribing. We will be in touch when the first classes launch.
                </div>
              }
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
      {/* <div className='HeroVideoBanner__title'>{props.title}</div> */}
    </div>
  );
};

export { HeroVideoBanner };