import { useRecoilValue } from 'recoil';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { invertSiteHeaderColorState } from 'state';
import { MobileMenu, SiteMenuHamburger } from 'components';
import { ROUTES } from 'routes';
import { ReactComponent as LogoIcon } from 'images/general/logo.svg';
import { ReactComponent as ZuckIcon } from 'images/icons/facebook.svg';
// import { ReactComponent as BirdIcon } from 'images/icons/twitter.svg';
// import { ReactComponent as CameraIcon } from 'images/icons/instagram.svg';
import './SiteHeader.scss';

type SiteHeaderProps = {};

const SiteHeader = (props: SiteHeaderProps) => {

  const invertColors = useRecoilValue(invertSiteHeaderColorState);

  const classes = clsx('SiteHeader', {
    'SiteHeader--invertColors': invertColors,
  });

  return (
    <div className={classes}>
      <div className="SiteHeader__grid">
        <div className='SiteHeader__logoWrap'>
          <Link className='SiteHeader__logo' to={ROUTES.HOME.path}>
            <LogoIcon className='SiteHeader__logoIcon' />
          </Link>
        </div>
        <div className="SiteHeader__nav">
          <Link className='SiteHeader__navLink' to={ROUTES.BOOKINGS.path}>Bookings</Link>
          <Link className='SiteHeader__navLink' to={ROUTES.OUR_TEACHING.path}>Our Teaching</Link>
          <Link className='SiteHeader__navLink' to={ROUTES.POLICIES.path}>Policies</Link>
          <Link className='SiteHeader__navLink' to={ROUTES.GALLERY.path}>Gallery</Link>
          {/* <Link className='SiteHeader__navLink' to={ROUTES.GALLERY.path}>Gallery</Link> */}
          {/* <Link className='SiteHeader__navLink' to={ROUTES.GALLERY.path}>Policies</Link> */}
          {/* <Link className='SiteHeader__navLink' to={ROUTES.GALLERY.path}>Contact</Link> */}
        </div>
        <div className="SiteHeader__quick">
          <SiteMenuHamburger className='SiteHeader__hamburger' />
          <a className='SiteHeader__quickItem SiteHeader__quickZuck' href='https://www.facebook.com/AfterCrafts'>
            <ZuckIcon className='SiteHeader__quickIcon' />
          </a>
          {/* <a className='SiteHeader__quickItem SiteHeader__quickCamera' href='https://www.instagram.com/aftercrafts'>
            <CameraIcon className='SiteHeader__quickIcon' />
          </a>
          <a className='SiteHeader__quickItem SiteHeader__quickBird' href='https://www.twitter.com/aftercraftsuk'>
            <BirdIcon className='SiteHeader__quickIcon' />
          </a> */}
        </div>
      </div>
      <MobileMenu />
    </div>
  );
};

export { SiteHeader };