import React from 'react';
import clsx from 'clsx';
import './LineList.scss';

type LineListProps = {
  className?: string;
  children?: React.ReactNode;
};

const LineList = (props: LineListProps) => {

  const classes = clsx('LineList', props.className);

  return (
    <div className={classes}>
      <div className='LineList__grid'>
        {props.children}
      </div>
    </div>
  );
};

export { LineList };
