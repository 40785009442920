import { atom } from 'recoil';

const mobileMenuVisibleState = atom({
  key: 'mobileMenuVisibleState',
  default: false,
});

const invertSiteHeaderColorState = atom({
  key: 'invertSiteHeaderColorState',
  default: false,
});

export {
  mobileMenuVisibleState,
  invertSiteHeaderColorState,
};
