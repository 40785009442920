import React from 'react';
import clsx from 'clsx';
import { AnimationDefinition, motion, Variants } from 'framer-motion';
import './RouteLayout.scss';

type RouteLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const RouteLayout = (props: RouteLayoutProps) => {

  const classes = clsx('RouteLayout', props.className);

  const routeMotion: Variants = {
    initial: { opacity: 0, y: '20px' },
    animate: { opacity: 1, y: '0px', transition: { duration: 0.4, ease: [.05, .4, 0, 1] } },
    exit: { opacity: 0, y: '20px', transition: { duration: 0.2, ease: [.05, .4, 0, 1] } }
  };

  // Scroll to top after the previous route has been animated out (exit)
  const handleAnimationComplete = (def: AnimationDefinition) => {
    if (def === 'exit') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className={classes}>
      <motion.div
        initial='initial'
        animate='animate'
        exit='exit'
        onAnimationComplete={handleAnimationComplete}
        variants={routeMotion}
      >
        {props.children}
      </motion.div>
    </div>
  );
};

export { RouteLayout };