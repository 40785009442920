import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { invertSiteHeaderColorState } from 'state';

export const useInvertSiteHeaderColor = (y: number) => {

  const [isInverted, setIsInverted] = useRecoilState(invertSiteHeaderColorState);

  useEffect(() => {

    setIsInverted(window.scrollY >= y);

    const updatePosition = () => {
      setIsInverted(window.scrollY >= y);
    };

    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
    }
  }, [setIsInverted, y]);

  return isInverted;
};
