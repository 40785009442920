import { RouteProps } from 'react-router';
import { HomeRoute } from 'routes/Home/HomeRoute';
import { BookingsRoute } from 'routes/Bookings/BookingsRoute';
import { GalleryRoute } from 'routes/Gallery/GalleryRoute';
import { OurTeachingRoute } from 'routes/OurTeaching/OurTeachingRoute';
import { PoliciesRoute } from 'routes/Policies/PoliciesRoute';
import { PoliciesBehaviourRoute } from 'routes/Policies/PoliciesBehaviourRoute';
import { PoliciesBookingRoute } from 'routes/Policies/PoliciesBookingRoute';
import { PoliciesDataProtectionRoute } from 'routes/Policies/PoliciesDataProtectionRoute';
import { PoliciesHealthAndSafetyRoute } from 'routes/Policies/PoliciesHealthAndSafetyRoute';
import { PoliciesSafeguardingRoute } from 'routes/Policies/PoliciesSafeguardingRoute';
import { Error404Route } from 'routes/Other/Error404Route';

type RouteItemProps = RouteProps & {
  path: string;
};

// The asRoutes function is used as an alternative to using the index signature below. The function ensures that autocomplete
// does not get broken.
//
// type Routes = {
//   [key: string]: RouteItemProps;
// };
//
// Taken from: https://stackoverflow.com/questions/52146544/why-autocomplete-stop-working-in-an-object-with-type-in-typescript
// Also see: https://youtrack.jetbrains.com/issue/WEB-34642

function asRoutes<T extends { [key: string]: RouteItemProps }>(arg: T): T {
  return arg;
}

const ROUTES = asRoutes({
  HOME: {
    path: '/',
    element: <HomeRoute />,
  },
  BOOKINGS: {
    path: '/bookings',
    element: <BookingsRoute />,
  },
  GALLERY: {
    path: '/gallery',
    element: <GalleryRoute />,
  },
  OUR_TEACHING: {
    path: '/our-teaching',
    element: <OurTeachingRoute />,
  },
  POLICIES_BEHAVIOUR: {
    path: '/policies/behaviour',
    element: <PoliciesBehaviourRoute />,
  },
  POLICIES_BOOKING: {
    path: '/policies/booking',
    element: <PoliciesBookingRoute />,
  },
  POLICIES_DATA_PROTECTION: {
    path: '/policies/data-protection',
    element: <PoliciesDataProtectionRoute />,
  },
  POLICIES_HEALTH_AND_SAFETY: {
    path: '/policies/health-and-safety',
    element: <PoliciesHealthAndSafetyRoute />,
  },
  POLICIES_SAFEGUARDING: {
    path: '/policies/safeguarding',
    element: <PoliciesSafeguardingRoute />,
  },
  POLICIES: {
    path: '/policies',
    element: <PoliciesRoute />,
  },
  CATCH_ALL: {
    path: '*',
    element: <Error404Route />
  },
});

export { ROUTES };
