import React, { useEffect } from 'react';
import { RouteProps, useNavigate } from 'react-router';
import { RouteLayout, SiteHelmet } from 'components';
import { ROUTES } from 'routes';

const Error404Route = (props: RouteProps) => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTES.HOME.path);
    }, 3500)
  }, [navigate]);

  return (
    <RouteLayout className='Error404Route'>

      <SiteHelmet title='Page Not Found - AfterCrafts'>
        <meta name="robots" content="noindex, nofollow" />
      </SiteHelmet>

      <div style={{ textAlign: 'center' }}>
        <h1>Welp. We couldn't find this page (404).</h1>
        <h3 style={{ color: '#fff' }}>Redirecting you back to the homepage.</h3>
      </div>

    </RouteLayout>
  );
};

export { Error404Route };
export default Error404Route;
