import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter, RegularBanner } from 'components';
import './PoliciesRoute.scss';
import imgBanner from 'images/banners/bannerOurTeaching.jpg';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';

const PoliciesRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(300);

  return (
    <RouteLayout className='PoliciesRoute'>

      <SiteHelmet title='Policies - AfterCrafts' />

      <RegularBanner
        image={imgBanner}
        title='Policies'
        subtitle='To ensure a safe and enjoyable experience for children at all our classes and to provide reassurance for parents and guardians we implement the following policies. Please read these carefully.'
      />

      <PageSection width='narrow'>
        <div className='PoliciesRoute__grid'>
          <div>
            <Link to={ROUTES.POLICIES_BEHAVIOUR.path}><h3>Behaviour Policy</h3></Link>
          </div>
          <div>
            <Link to={ROUTES.POLICIES_BOOKING.path}><h3>Booking Policy</h3></Link>
          </div>
          <div>
            <Link to={ROUTES.POLICIES_DATA_PROTECTION.path}><h3>Data Protection Policy</h3></Link>
          </div>
          <div>
            <Link to={ROUTES.POLICIES_HEALTH_AND_SAFETY.path}><h3>Health and Safety Policy</h3></Link>
          </div>
          <div>
            <Link to={ROUTES.POLICIES_SAFEGUARDING.path}><h3>Safeguarding Policy</h3></Link>
          </div>
        </div>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { PoliciesRoute };
export default PoliciesRoute;