import clsx from 'clsx';
import './RegularBanner.scss';

type RegularBannerProps = {
  className?: string;
  image: string;
  title: string;
  subtitle?: string;
};

const RegularBanner = (props: RegularBannerProps) => {

  const classes = clsx('RegularBanner', props.className);

  return (
    <div className={classes}>
      <div className='RegularBanner__image' style={{ backgroundImage: `url(${props.image})` }}>
        <div className="RegularBanner__text">
          <h1 className="RegularBanner__title u-font-brand">{props.title}</h1>
          {props.subtitle &&
            <div className="RegularBanner__subtitle">{props.subtitle}</div>
          }
        </div>
      </div>
    </div>
  );
};

export { RegularBanner };
