import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';
import imgCraft01 from './images/IMG_1677.jpg';
import imgCraft02 from './images/IMG_1816.jpg';
import imgCraft03 from './images/IMG_1850.jpg';
import imgCraft04 from './images/IMG_1855.jpg';
import imgCraft05 from './images/IMG_2858.jpg';
import imgCraft06 from './images/IMG_2863.jpg';
import imgCraft07 from './images/IMG_2907.jpg';
import imgCraft08 from './images/IMG_2949.jpg';
import imgCraft09 from './images/IMG_2957.jpg';
import imgCraft10 from './images/IMG_2990.jpg';
import imgCraft11 from './images/IMG_2998.jpg';
import imgCraft12 from './images/IMG_3049.jpg';
import imgCraft13 from './images/IMG_3059.jpg';
import imgCraft14 from './images/IMG_3060.jpg';
import imgCraft15 from './images/IMG_3076.jpg';
import imgCraft16 from './images/IMG_3086.jpg';
import imgCraft17 from './images/IMG_3105.jpg';
import imgCraft18 from './images/IMG_3168.jpg';
import './GalleryRoute.scss';

const GalleryRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='GalleryRoute'>

      <SiteHelmet title='Gallery - AfterCrafts' />

      <PageSection width='full'>
        <div className='GalleryRoute__grid'>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft17})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft18})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft16})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft15})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft14})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft12})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft13})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft11})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft10})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft09})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft08})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft07})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft06})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft05})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft03})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft02})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft04})` }}></div>
          <div className='GalleryRoute__photo' style={{ backgroundImage: `url(${imgCraft01})` }}></div>
        </div>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { GalleryRoute };
export default GalleryRoute;