import React from 'react';
import clsx from 'clsx';
import './PageSection.scss';

type PageSectionProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  width: 'narrow' | 'regular' | 'full';
};

const PageSection = (props: PageSectionProps) => {

  const classes = clsx('PageSection', props.className);
  const contentClasses = clsx('PageSection__content', 'PageSection__content--' + props.width);

  return (
    <div className={classes} style={props.style}>
      <div className='PageSection__contentWrapper'>
        <div className={contentClasses}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export { PageSection };
