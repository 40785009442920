import './SiteFooter.scss';

type SiteFooterProps = {};

const SiteFooter = (props: SiteFooterProps) => {

  const year = (new Date()).getUTCFullYear();

  return (
    <div className='SiteFooter'>
      <div className='SiteFooter__grid'>
        <small>&copy; AfterCrafts {year}</small>
      </div>
    </div>
  );
};

export { SiteFooter };