import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { SiteHeader } from 'components';
import { ROUTES } from 'routes';
import './App.scss';

const App = () => {

  const location = useLocation();

  return (
    <div className='App'>
      <SiteHeader />
      <AnimatePresence mode='wait' >
        <Routes location={location} key={location.pathname}>
          {Object.values(ROUTES).map((item, idx) =>
            <Route {...item} key={idx} />
          )}
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export { App };
