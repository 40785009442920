import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';

const PoliciesHealthAndSafetyRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='PoliciesHealthAndSafetyRoute'>

      <SiteHelmet title='Health and Safety Policy - AfterCrafts' />

      <PageSection width='narrow'>
        <h1>Health and Safety Policy</h1>
        <p>At AfterCrafts the safety and well-being of all participants is our utmost priority. We have implemented the following health and safety measures to ensure a secure environment for everyone:</p>
        <h3>Risk assessment</h3>
        <p>We conduct regular risk assessments of our class environment, materials, and activities to identify and minimise potential hazards.</p>
        <p>We take into account age-appropriate considerations and make adjustments accordingly.</p>
        <h3>Qualified instructors</h3>
        <p>Our instructors are experienced and trained in providing a safe and supportive environment for children during craft activities. They possess a good understanding of health and safety guidelines and will actively promote safe practices throughout the classes.</p>
        <h3>Safe class environment</h3>
        <p>We maintain a clean and clutter-free class area to minimise the risk of accidents or injuries. Adequate space is provided for each child to ensure they can work comfortably and safely. Any equipment or furniture used in the class is inspected regularly to ensure its safety and functionality</p>
        <h3>Age-appropriate activities and materials</h3>
        <p>We carefully select craft activities and materials that are suitable for the age range of the participants. Any potentially hazardous materials or tools are used under close supervision and only when appropriate for the children's skill level.</p>
        <h3>First Aid and emergency procedures</h3>
        <p>We will make all children aware of the nearest emergency exit in case they need to vacate the building. Our instructors are trained in basic first aid procedures and will be prepared to respond to minor injuries or accidents that may occur during the class. First aid kits are readily available on-site, and emergency contact information for each participant will be collected and easily accessible. </p>
        <h3>Illness, allergies and medical conditions</h3>
        <p>If your child is showing any symptoms of illness, please do not send them to the class. Prior to the class, parents/guardians are required to inform us of any known allergies or medical conditions that may affect their child's participation. </p>
        <p>We will make reasonable accommodations to ensure the safety and well-being of children with specific allergies or medical needs.</p>
        <h3>COVID-19</h3>
        <p>If your child is showing any symptoms of COVID-19 please do not send them to the class.</p>
        <p>We will encourage children to wash their hands regularly, wipe down surfaces after each class, and where possible, clean all the equipment that they use eg, scissors, paintbrushes. </p>
        <p>In the unlikely event that we are obligated to cease classes due to COVID-19 restrictions, children will be sent activity packs each week so that they can complete crafts at home. We will be available to offer support online or via video calls if they have any difficulty completing the crafts or if they would simply like to show us how they got along. This system will continue until we are able to resume in-person teaching. </p>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { PoliciesHealthAndSafetyRoute };
export default PoliciesHealthAndSafetyRoute;