import React from 'react';
import clsx from 'clsx';
import './TwoColumnCard.scss';

type TwoColumnCardProps = {
  className?: string;
  children?: React.ReactNode;
};

const TwoColumnCard = (props: TwoColumnCardProps) => {

  const classes = clsx('TwoColumnCard', props.className);

  return (
    <div className={classes}>
      <div className='TwoColumnCard__grid'>
        {props.children}
      </div>
    </div>
  );
};

export { TwoColumnCard };
