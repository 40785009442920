import React from 'react';
import { Helmet } from 'react-helmet-async';

type SiteHelmetProps = {
  title?: string;
  children?: React.ReactNode;
};

const SiteHelmet = (props: SiteHelmetProps) => {

  return (
    <Helmet>
      {props.title && <title>{props.title}</title>}
      {props.children}
    </Helmet>
  );
};

export { SiteHelmet };