import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { mobileMenuVisibleState } from 'state';
import { ROUTES } from 'routes';
import './MobileMenu.scss';

type SiteMenuProps = {};

const MobileMenu = (props: SiteMenuProps) => {

  const [mobileMenuVisible, setMobileMenuVisible] = useRecoilState(mobileMenuVisibleState);

  const closeMobileMenu = () => {
    setMobileMenuVisible(false);
  };

  return (
    <AnimatePresence>
      {mobileMenuVisible &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4, ease: [.05, .4, 0, 1] } }}
          exit={{ opacity: 0, transition: { duration: 0.2, ease: [.05, .4, 0, 1] } }}
        >
          <div className="MobileMenu" >
            <nav className='MobileMenu__nav'>
              <Link
                className='MobileMenu__navItem'
                to={ROUTES.HOME.path}
                onClick={closeMobileMenu}>
                Home
              </Link>
              <Link
                className='MobileMenu__navItem'
                to={ROUTES.BOOKINGS.path}
                onClick={closeMobileMenu}>
                Bookings
              </Link>
              <Link
                className='MobileMenu__navItem'
                to={ROUTES.OUR_TEACHING.path}
                onClick={closeMobileMenu}>
                Our Teaching
              </Link>
              <Link
                className='MobileMenu__navItem'
                to={ROUTES.GALLERY.path}
                onClick={closeMobileMenu}>
                Gallery
              </Link>
              <Link
                className='MobileMenu__navItem'
                to={ROUTES.POLICIES.path}
                onClick={closeMobileMenu}>
                Policies
              </Link>
              {/* <Link
                className='MobileMenu__navItem'
                to={ROUTES.GALLERY.path}
                onClick={closeMobileMenu}>
                Gallery
              </Link> */}
            </nav>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  );
};

export { MobileMenu };
