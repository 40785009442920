import React from 'react';
import clsx from 'clsx';
import './CircularImageTextCard.scss';

type CircularImageTextCardProps = {
  className?: string;
  image: string;
  children?: React.ReactNode;
};

const CircularImageTextCard = (props: CircularImageTextCardProps) => {

  const classes = clsx('CircularImageTextCard', props.className);

  return (
    <div className={classes}>
      <div className='CircularImageTextCard__imageBlock'>
        <div className="CircularImageTextCard__imageBlockBack"></div>
        <div className='CircularImageTextCard__image' style={{ backgroundImage: `url(${props.image})`}}></div>
      </div>
      <div className='CircularImageTextCard__textBlock'>
        {props.children}
      </div>
    </div>
  );
};

export { CircularImageTextCard };
