import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';

const PoliciesBehaviourRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='PoliciesBehaviourRoute'>

      <SiteHelmet title='Behaviour Policy - AfterCrafts' />

      <PageSection width='narrow'>
        <h1>Behaviour Policy</h1>
        <p>To ensure that all participants get the most out of our classes we have developed the following behaviour policy:</p>
        <p>Children are expected to treat each other and their instructors with respect and kindness. Bullying, teasing, or any form of harmful behaviour will not be tolerated.</p>
        <p>Children should listen attentively to the instructions provided by the instructors and follow them accordingly. Failure to follow instructions may result in potential safety hazards and disruptions to the activities.</p>
        <p>They should handle art materials, tools, and equipment responsibly, adhering to the safety guidelines provided by the instructors.</p>
        <p>Whilst there will be enough materials for all children to complete their projects children are asked to me mindful of their classmates and not use more than their fair share.</p>
        <p>Children are responsible for their personal belongings during club sessions. The club is not responsible for any lost, damaged, or stolen items.</p>
        <p>Children should arrive on time for each session to maximise their participation and avoid disruptions.</p>
        <p>Parents or guardians should actively support and encourage their children's participation in  AfterCrafts. They should communicate any concerns or issues to the Instructor promptly.</p>
        <p>In the event of persistent disruptive behaviour, instructors will discuss the problem with the parents or guardians. If the problem reaches a point where we feel that the child is ruining the experience of AfterCrafts for other children, they will be excluded from future sessions. If this happens to your child you will receive a refund for any sessions that have been paid for that your child can no longer attend.</p>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { PoliciesBehaviourRoute };
export default PoliciesBehaviourRoute;