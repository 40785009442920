import React from 'react';
import clsx from 'clsx';
import './ImageStack.scss';

type ImageStackProps = {
  className?: string;
  images: string[];
};

const ImageStack = (props: ImageStackProps) => {

  const classes = clsx('ImageStack', props.className);

  return (
    <div className={classes}>
      <div className='ImageStack__grid'>
        {props.images.map((item, idx) =>
          <div className='ImageStack__image' style={{ backgroundImage: `url(${item})` }} key={idx}></div>
        )}
      </div>
    </div>
  );
};

export { ImageStack };
