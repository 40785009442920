import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';

const PoliciesSafeguardingRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='PoliciesSafeguardingRoute'>

      <SiteHelmet title='Safeguarding Policy - AfterCrafts' />

      <PageSection width='narrow'>
        <h1>Safeguarding Policy</h1>
        <p>This safeguarding policy outlines the commitment of AfterCrafts to ensuring the welfare and protection of all children attending our classes. It will be reviewed annually and any changes will be bought to the attention of both staff and parents and guardians. </p>
        <h3>1. Legal framework</h3>
        <p>Our safeguarding policy adheres to the legal requirements and guidelines set forth in the United Kingdom, including but not limited to:</p>
        <ul>
          <li>The Children Act 1989 and 2004</li>
          <li>Working Together to Safeguard Children (2018)</li>
          <li>The Education Act 2002</li>
          <li>The Protection of Freedoms Act 2012</li>
          <li>Disclosure and Barring Service (DBS) checks and procedures</li>
          <li>The Safeguarding Vulnerable Groups Act 2006</li>
        </ul>
        <h3>2. Recruitment and training</h3>
        <p>At present, the founder, Deborah Mistry is the only member of staff. She holds an Enhanced DBS certificate and has passed the Level 2 Child Safeguarding course. Both of these certificates are available to view on request. Any staff recruited in the future will also have to undergo DBS screening, provide character references and also pass the Safeguarding course. </p>
        <p>We do not allow staff to baby-sit for students of AfterCrafts or become ‘friends’ (in the sense of social media) with them. </p>
        <p>Regular safeguarding training will be provided to staff to ensure they understand their roles and responsibilities.</p>
        <p>Staff will be made aware of the signs and symptoms of abuse, and the procedure to follow in case of disclosure or suspicion.</p>
        <h3>3. Reporting and responding to a disclosure of abuse by a child</h3>
        <p>All businesses working with children have a duty of care. On being in receipt of a disclosure staff will first of all tell the child that because they have a duty of care, they have to tell someone else about what was just said.</p>
        <p>After the session, the member of staff will telephone the local County Council child protection service to give details of the child, their parents, and other details the child protection service may require. In reporting disclosures, it’s recognised that child protection is a higher priority than data protection.</p>
        <h3>4. Creating a safe environment</h3>
        <p>The physical environment of our craft classes will be chosen and adapted if necessery to ensure the safety and security of children, including adequate supervision, appropriate seating arrangements, and accessible emergency exits. For further details on this, please see our Health and Safety Policy. </p>
        <p>Children will be working in an open plan shared space, and parents can expect no child to be isolated in the normal course of events. Children are tutored on an individual basis and this one to one discussion will be held in the club at a table shared by other children.</p>
        <p>We expect a mobile phone number to be able to call the parent at any time during the club session. If for any reason this mobile is not available, a second backup number is also required.</p>
        <h3>5. Physical contact</h3>
        <p>In general we will discourage all physical contact between staff and children. If children get paint or clay on themselves they will be instructed to clean themselves or wait until they get home. Very occasionally it might be necessary to help them. </p>
        <p>There may be occasions when children are upset for whatever reason. On these occasions, the staff will do their best to comfort them verbally. The pupil will not be removed from the open space to be given such comfort and will be in clear sight of all members of staff and club. Depending on the level and cause of their distress we might call and ask a parent to collect them early. If they begin to cheer up and wish to continue with their craft activity we will still let their parent or guardian know that they were upset during the class.</p>
        <p>Children must be fully toilet trained to come to our classes as under no circumstances can staff assist them in the toilet. If they have an accident or vomit parents will be called immediately and asked to collect them. </p>
        <h3>6. Drop off/Pick up</h3>
        <p>Children will be expected to be accompanied by their parent across the threshold of the club and will also be expected to pick up their child from the club promptly.</p>
        <p>Children are required to be left in the club for the duration of the session. Delaying leaving by more than a couple of minutes or returning before the appointed finish time, is not allowed for the reason of safety for all children- unless by prior arrangement which can be verbally agreed on the day.</p>
        <p>When you book with us we will ask for the names of anyone who will regularly 	be collecting your child from AfterCrafts. If there are any changes to this, please let us know in writing. We will not allow children to leave with anyone who we have not been informed about. </p>
        <h3>7. Social media</h3>
        <p>We share images of children’s craft projects on our social media platforms. However, we will never show any images of children without consent from their parents or guardians (obtained at the point of booking). Any images shown (regardless of whether it is of a child or a finished craft project) will not include any names or any indicator that could help identify the child. </p>


      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { PoliciesSafeguardingRoute };
export default PoliciesSafeguardingRoute;