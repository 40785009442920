import { useRecoilState } from 'recoil';
import { mobileMenuVisibleState } from 'state';
import clsx from 'clsx';
import './SiteMenuHamburger.scss';

type SiteMenuHamburgerProps = {
  className?: string;
};

const SiteMenuHamburger = (props: SiteMenuHamburgerProps) => {

  const [mobileMenuVisible, setMobileMenuVisible] = useRecoilState(mobileMenuVisibleState);

  const handleClick = () => {
    setMobileMenuVisible(state => !state);
  };

  const classes = clsx('SiteMenuHamburger', props.className, {
    'SiteMenuHamburger--active': mobileMenuVisible,
  });

  return (
    <button className={classes} onClick={handleClick}>
      <div className="SiteMenuHamburger__icon"></div>
    </button>
  );
};

export { SiteMenuHamburger };
