import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';

const PoliciesBookingRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='PoliciesBookingRoute'>

      <SiteHelmet title='Booking Policy - AfterCrafts' />

      <PageSection width='narrow'>
        <h1>Booking Policy</h1>
        <p>By registering for AfterCrafts, you acknowledge that you have read, understood, and agreed to abide by the booking policy outlined below. We reserve the right to modify the policy if necessary, and any changes will be communicated to you in a timely manner.</p>
        <p>If you have any further questions or concerns, please don't hesitate to contact us.</p>
        <h3>Preparing your child for classes</h3>
        <p>Children should bring their own water bottle to class as there will not be the opportunity to fetch them drinks. They can refill this at any time as tap water will be available at all venues. </p>
        <p>Please note that we use some paints that could stain clothes so please send children in either old clothes or an art smock, apron or old t-shirt to wear over their clothes. We are not able to provide these and we cannot take any responsibility for clothing that is damaged during class. </p>
        <h3>Class cancellations and catch-up classes</h3>
        <p>If your child is not able to attend a class we are unfortunately not able to offer a refund as the space was still held for them. </p>
        <p>In the event that a class is canceled by us due to unforeseen circumstances such as instructor illness, a catch-up class will be offered to all affected participants. This might need to take place during the school holidays or on a different day to when the class is normally run. If your child is not able to attend this class then they will get one free class during the next term. It is your responsibility to mention this at the point of booking for the next term so that the fee can be discounted accordingly.	</p>
        <h3>Priority booking</h3>
        <p>When booking opens for the next term, children who already attend the classes will have priority booking for a period of 1 week before booking becomes available to everyone. Please note that there is no priority booking for classes operating within schools.</p>
        <h3>Payment</h3>
        <p>To secure a spot for your child at AfterCrafts we require full payment for the entire term in advance. If spaces remain, your child is allowed to join mid-term and the fee will be adjusted accordingly so that you only pay for the remaining sessions. </p>
        <p>Payment must be made via our online booking system.</p>
        <p>Once payment is received, your child's spot will be reserved for the entire term (or what remains of it if they join part way through)</p>
        <h3>Referral discount</h3>
        <p>We value your support and appreciate referrals. If you refer a friend who subsequently books a term (or what remains of a term if they join part of the way through) at AfterCrafts you will be eligible for a 15% discount on the next term's classes.</p>
        <p>To qualify for the referral discount, your friend must mention your name during the booking process, and their registration must be successfully completed. The 15% discount will be applied to your next term's classes and will be deducted from the total cost.</p>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { PoliciesBookingRoute };
export default PoliciesBookingRoute;