import { RouteProps } from 'react-router';
import { HeroVideoBanner, PageSection, RouteLayout, SiteHelmet, TwoColumnCard, SiteFooter, CircularImageTextCard } from 'components';
import './HomeRoute.scss';
import imgBanner from 'images/banners/bannerHome.jpg';
import imgCraft1 from './images/craft1.jpg';
import imgCraft2 from './images/craft2.jpg';
import imgCraft3 from './images/craft3.jpg';
import imgAvatar from './images/avatar.jpg';
import { ImageStack } from 'components/ImageStack';
import { useInvertSiteHeaderColor } from 'hooks';

const HomeRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(500);

  return (
    <RouteLayout className='HomeRoute'>

      <SiteHelmet title='AfterCrafts - After-school, weekend and holiday craft classes for children in Surrey' />

      <HeroVideoBanner title='After-school, weekend and holiday craft classes for children in Surrey' image={imgBanner} />

      <PageSection width='regular'>
        <TwoColumnCard>
          <div>
            <p style={{ fontSize: '1.25em', marginBottom: '1.5em', marginTop: '0' }}>With a focus on fun, creativity, and personal expression, AfterCraft's children's after-school and weekend craft classes offer a mindful and rewarding experience for young makers of all skill levels.</p>
            <p>We keep things fresh and engaging by utilising a wide range of materials and techniques on our projects (all of which have been pre-tested for age appropriateness and effectiveness). Children do not need to be good at fine art to enjoy the classes. All materials are provided, so children can simply come and create!</p>
            <p>We believe in nurturing self expression and encourage children to adapt their projects to their personal tastes. Additionally, we invite them to submit their own project ideas, which we can then incorporate into future sessions.</p>
            <p>We look forward to welcoming your child to AfterCrafts, having fun, and making some beautiful things together!</p>
            <p>
              Have a question? <a className='underline' href='mailto:info@aftercrafts.co.uk'>Mail us at info@aftercrafts.co.uk</a>
            </p>
          </div>
          <div>
            <ImageStack images={[imgCraft1, imgCraft2, imgCraft3]} />
          </div>
        </TwoColumnCard>
      </PageSection>

      <PageSection width='regular'>
        <CircularImageTextCard image={imgAvatar}>
          <p style={{ fontSize: '1.25em', marginBottom: '1.5em', marginTop: '1.5em' }}>Hello! I'm Deborah, the founder of AfterCrafts. I have been passionate about making things my entire life and originally made my career in product development specialising in giftware and children's activity sets.</p>
          <p>Having found the experience of homeschooling my son during lockdowns unexpectedly rewarding, I saw an opportunity to combine my love of crafting with my new found interest in teaching. I started working at both a local school and the UK's leading extracurricular art class provider and gained experience in working with children of all ages and abilities (including those with special educational needs).</p>
          <p style={{ marginBottom: 0 }}>I believe passionately in the value of craft and nurturing creativity for children's mental health and future development. I know first-hand how therapeutic and rewarding it is to experiment with new materials, try out different techniques, and create something that you can be truly proud of. And, best of all, I love sharing that joy with others!</p>
        </CircularImageTextCard>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { HomeRoute };
export default HomeRoute;
