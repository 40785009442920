import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter } from 'components';
import './BookingsRoute.scss';

const BookingsRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(0);

  return (
    <RouteLayout className='BookingsRoute'>

      <SiteHelmet title='Bookings - AfterCrafts' />

      <PageSection width='narrow'>
        <iframe
          title="bookingIframe"
          src="https://bookwhen.com/aftercrafts/iframe"
          seamless={true}
          style={{ display: 'block', border: 'none', width: '100%', height: '5000px'}}>
        </iframe>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { BookingsRoute };
export default BookingsRoute;