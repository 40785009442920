import { RouteProps } from 'react-router';
import { useInvertSiteHeaderColor } from 'hooks';
import { PageSection, RouteLayout, SiteHelmet, SiteFooter, RegularBanner, LineList } from 'components';
import './OurTeachingRoute.scss';
import imgBanner from 'images/banners/bannerOurTeaching.jpg';

const OurTeachingRoute = (props: RouteProps) => {

  useInvertSiteHeaderColor(300);

  return (
    <RouteLayout className='OurTeachingRoute'>

      <SiteHelmet title='Our Teaching - AfterCrafts' />

      <RegularBanner
        image={imgBanner}
        title='Our Teaching'
        subtitle='We aim to inspire children to enjoy a lifetime of crafting by showing them how making things can be both fun, therapeutic and an outlet to express and develop their creativity.'
      />

      <PageSection width='narrow'>
        <LineList className='OurTeachingRoute__faq'>
          <div>
            <h3>What crafts do you teach?</h3>
            <p>Children will work on a wide variety of projects from paper mache to fabric and glass painting, beading, clay modelling, decoupage and print making.</p>
          </div>
          <div>
            <h3>Do all children work on the same thing?</h3>
            <p>Generally speaking, yes.</p>
            <p>As we are not studio based but go into schools or hired spaces to teach we have to take all materials with us that we will need for each project. It is not practical for us to take a different set of materials for every child. However, we recognise that everyone works at a different pace and we would never want to rush a child to finish a project just because their classmates had. Therefore we operate the same system as mainstream primary education; we offer 'extension' tasks to the faster working children which they can complete whilst their classmates catch up. We also hold the more advanced students to higher standards which tends to slow them down somewhat.</p>
            <p>So that they can make the most of each class many children will have two projects on the go at the same time so that they are not just sat there whilst paint or glue are drying!</p>
          </div>
          <div>
            <h3>Can children take things home to finish?</h3>
            <p>We prefer all projects to be finished in class so that we can ensure that they are done properly. However, there are some exceptions to this. For example, if a child misses a class in which the other children finished the project, and the project does not require any special materials to complete it, it is easier for us to send it home to finish rather than delay the whole class starting the next project. In this instance the child will be given clear instructions on what they need to do and if necessary it will also be discussed with the parents.</p>
            <p>Another exception would be if a project doesn't go very well and we have allowed the child to start again in class, we might still let them take the first attempt home with them to see if they can adapt it to something else, rather than just waste it.</p>
          </div>
          <div>
            <h3>Will children bring home something after every class?</h3>
            <p>No. We pride ourselves on teaching, slow, mindful crafts rather than quick projects that only involve sticking a few things together and show little creativity. Most of our projects will take at least two weeks and due to drying time, it might be three weeks until they are sent home.</p>
          </div>
          <div>
            <h3>Can children bring in their own materials?</h3>
            <p>Ideally no, because they might get muddled up with AfterCrafts materials and it can also cause jealousy in other children. However if there is one small special thing that they would particularly like to incorporate into a project then we can allow this.</p>
          </div>
          <div>
            <h3>If a child doesn't enjoy a project, can they give up on it and move onto the next one?</h3>
            <p>We always try and encourage children to finish things properly. However as this is an extracurricular class and meant to be fun we don't want to force a child to do something they are really not enjoying! In most instances we would discuss with them how the project could be adapted so that they find it more enjoyable and are happy to finish it, even if it ends up as something rather different to what we originally had planned!</p>
          </div>
          <div>
            <h3>Do we teach children painting and drawing?</h3>
            <p>No. There might be some painting or drawing involved in a lot of our projects but we rarely just give children a piece of paper or canvas to work on, there is almost always a 3D element. We believe that a lot of children are incredibly creative without being either interested or particularly talented at fine art and AfterCrafts gives those children a chance to shine! Having said that if your child is a talented, painter/sketcher there will be the chance to use and develop those skills too as there is flexibility within every project.</p>
          </div>
        </LineList>
      </PageSection>

      <SiteFooter />

    </RouteLayout>
  );
};

export { OurTeachingRoute };
export default OurTeachingRoute;